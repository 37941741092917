.App {
  text-align: center;
}


.primary_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.html-content > p > img {
  max-width:  100%;
}



html {
  height: -webkit-fill-available;
}

*::-webkit-scrollbar {
  display: none;
}

html, body {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  display: flex; 
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;
}

main {
  flex: 1;
  width: 100%;
  max-width: 800px;
}

.footer {
  width: calc(100% - 40px);
  margin-bottom: 30px;
  max-width: 800px;
}

.MuiOutlinedInput-root legend {
  font-size: 0.9rem
}


.img-gradient img{
  display:block;
}

.spinner {
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}